import React from "react"
import Layout from "../layouts"
import { themeGet } from 'styled-system'
import { Container } from '../components/Panel'

import styled, { keyframes } from 'styled-components'

const headerKeyframes = keyframes`
	0% {width:100%}
	10% {width:99%}
	20% {width:98%}
	30% {width:97%}
	40% {width:96%}
	50% {width:95%}
	55% {width:94%}
	60% {width:40%}
	80% {width:20%;}
	100% {width:0%;}
`

const CoolBox = styled.div`
	position:absolute;
    top:0; 
    bottom:0; 
    right: 0;
    width: 0%;
    height: 158px;
    background:white;
    animation: ${headerKeyframes} 4s ease-out 0s 1;
`

const CircleKeyFrames = keyframes`
	0% {
		opacity: 0;
		transform: translate(300px, 300px);
		background: url(/img/landing-back-1.png);
		background-image: linear-gradient(133deg,#998872 1%,#ffffff 6%);
	}
	10% {
		opacity: 0.1;
		background-image: linear-gradient(133deg,#998872 1%,#ffffff 8%);
	}
	20% {
		opacity: 0.2;
		background-image: linear-gradient(133deg,#998872 1%,#ffffff 10%);
	}
	30% {
		opacity: 0.3;
		background-image: linear-gradient(133deg,#998872 1%,#ffffff 12%);
	}
	40% {
		opacity: 0.4;
		background-image: linear-gradient(133deg,#998872 1%,#ffffff 20%);
	}
	50% {
		opacity: 0.5;
		background-image: linear-gradient(133deg,#998872 1%,#ffffff 26%);
	}
	60% {
		opacity: 0.6;
		background-image: linear-gradient(133deg,#998872 1%,#ffffff 30%);
	}
	70% {
		opacity: 0.7;
		background-image: linear-gradient(133deg,#998872 1%,#ffffff 36%);
	}
	80% {
		opacity: 0.8;
		background-image: linear-gradient(133deg,#998872 1%,#ffffff 40%);
	}
	90% {
		opacity: 0.9;
		background-image: linear-gradient(133deg,#998872 1%,#ffffff 50%);
	}
	100% {
		opacity: 1;
		background-image: linear-gradient(133deg,#998872 1%,#ffffff 56%);
		transform: translate(0, 0);
	}
`

const descriptionKeyFrames = keyframes`
	0% {
		opacity: 0;
		transform: translate(200px, 200px);
	}
	100% {
		opacity: 1;
		transform: translate(0, 0);
	}
`
const Circle = styled.div`
	width: 100%;
	position: relative;
	margin-top: -14%;
	padding-top: 100%;
	border-radius: 50%;
	animation: ${CircleKeyFrames} 1s linear 0s 1;
	animation-delay: 1s;
	animation-fill-mode: forwards; 
	& h3 {
		margin-top: -50%;
		padding-left: 50%;
		font-weight: normal;
		font-size: 36px;
		opacity: 0;
		animation: ${descriptionKeyFrames} 0.7s linear 0s 1;
		animation-delay: 2s;
		animation-fill-mode: forwards; 
	}

	@media (max-width: ${themeGet('breakpoints.1')}) {
		& h3 {
			font-size: 30px;
		}
	}

	@media (max-width: ${themeGet('breakpoints.0')}) {
		& h3 {
			display: none;
		}
	}
`

const SubHeaderKeyframes = keyframes`
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
`
const HiddenHeaderKeyframes = keyframes`
	0% {
		margin-top: 35%;
		padding-left: 35%;
		opacity: 1;
	}
	100% {
		margin-top: 0;
		padding-left: 0;
		opacity: 0;
	}
`
const DotKeyframes = keyframes`
	0% {
		margin-left: 0px;
	}
	100% {
		margin-left: -2em;
	}
`
const HiddenHeader = styled.h1`
	margin-top: 35%;
	padding-left: 35%;
	font-size: 4.8em;
	animation: ${HiddenHeaderKeyframes} 0.5s ease-out 0s 1;
	animation-delay: 1.5s;
	animation-fill-mode: forwards;
	@media (max-width: ${themeGet('breakpoints.2')}) {
		padding-left: 25%;
	}

	@media (max-width: ${themeGet('breakpoints.1')}) {
		font-size: 3em;
		padding-left: 20%;
	}
	
`

const SubHeader = styled.span`
	animation: ${SubHeaderKeyframes} 0.5s ease-out 0s 1;
	animation-delay: 0.5s;
	animation-fill-mode: forwards; 
`

const Dot = styled.span`
	animation: ${DotKeyframes} 0.5s ease-out 0s 1;
	animation-delay: 1s;
	animation-fill-mode: forwards; 
`

const Text = styled.h3`
	display: none;
	opacity: 0;
	animation: ${descriptionKeyFrames} 0.7s linear 0s 1;
	animation-delay: 1.5s;
	animation-fill-mode: forwards; 
	@media (max-width: ${themeGet('breakpoints.0')}) {
		display: block;
		position: relative;
		font-weight: normal;
		font-size: 24px;
	}
`
const Contents = Container.extend`
	min-height: calc(100vh - 158px);
	padding: 7.5em 0;
	@media (max-width: ${themeGet('breakpoints.6')}) {
    	padding: 0;
	}
`
const IndexPage = () => (
	<Layout page={"landing"}>
		<Contents>
			<CoolBox />
			<HiddenHeader>dogan <SubHeader>gök</SubHeader><Dot>.</Dot></HiddenHeader>
			<Circle>
				<h3>I’m Dogan. I build and launch artificial intelligence based products and companies.</h3>
			</Circle>

			<Text>I’m Dogan. I build and launch artificial intelligence based products and companies.</Text>
		</Contents>
	</Layout>
)

export default IndexPage
